import { useState,useContext,useEffect } from 'react';
import { useRouter } from 'next/router';
import {ThemeContext} from "../../contexts/ThemeContext";
import { UserContext } from '../../contexts/UserContext';
// props for search bar available - 13/08/2024
function MobileSearchBar({ setClose = () => false}) {
    const [searchValue, setSearchValue] = useState('');
    const { featureCustomizations, metalCategories } = useContext(UserContext);
    const [searchKey, setSearchKey] = useState(['1', '2', '3']);
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const router = useRouter();
    const theme = useContext(ThemeContext);
    const handleSearch = (e) => {
        e.preventDefault();
        const searchUrl = theme.siteStatus === 0  ? `/search/${searchValue}?pid=${theme.token}` : `/search/${searchValue}`;
        router.push(searchUrl);
        // window.location.href=searchUrl;
        setSearchValue('');
        // inputRef.current.blur();
        setClose(false);
      };

      useEffect(() => {
        if (featureCustomizations && featureCustomizations.is_search_key_enabled == 1 && featureCustomizations.search_key)  {
          setSearchKey(featureCustomizations.search_key);
        } else {
            getCategoryNames(metalCategories)
        }
      }, [featureCustomizations, metalCategories]);

      const getCategoryNames = (names) => {
        var totalItems = [];
        if (!Array.isArray(names)) {
            console.error('Names is not an array');
            return;
        }
        names.forEach(item => {
            totalItems.push(item.name);
            if (item.child) {
                item.child.forEach(subItem => {
                    totalItems.push(subItem.name);
                    if (subItem.child) {
                        subItem.child.forEach(innerItem => {
                            totalItems.push(innerItem.name);
                        });
                    }
                });
            }
        });
        setSearchKey(totalItems);
    };

      const cycleProducts = () => {
        setCurrentProductIndex(Math.floor(Math.random() * searchKey.length));
    };

    useEffect(() => {
        const interval = setInterval(cycleProducts, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
    <div className="bg-white shadow-sm flex items-center">
        <div className="w-full mx-auto p-2">
            <div className="relative flex items-center">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                    <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-lg text-primary">
                        <i className="fas fa-search"></i>
                    </span>
                    <input
                        id="search_field"
                        name="search_field"
                        className="block w-full h-10 pl-12 pr-3 py-2 border border-r-0 border-primary bg-white text-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary"
                        placeholder={
                            searchKey && searchKey[currentProductIndex]
                              ? `Search for ${searchKey[currentProductIndex]}`
                              : 'Search'
                          }
                        type="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button
                        type="submit"
                        onClick={handleSearch}
                        className="bg-primary border border-primary text-white px-8 font-medium hover:bg-transparent hover:text-primary transition"
                    >
                        Search
                    </button>
                </form>
            </div>
        </div>
    </div>
    
    );
}

export default MobileSearchBar;
