import React, { useState, useEffect } from 'react';
import { setCookie, getCookie, deleteCookie } from '../utils/cookie'; // Adjust the import path as necessary

// Creating a User context with default values
const UserContext = React.createContext({
  userId: "",
  token: null,
  userDetails: null,
  clearUser: () => {}, // Add a clearUser function to the default context
});

/**
 * UserProvider component to manage and provide user state to the application.
 * 
 * This component handles user authentication state including userId, token, and userDetails.
 * It provides functions to update these states and to clear them when the user logs out.
 * 
 * @param {React.ReactNode} children - The child components of UserProvider.
 */
const UserProvider = ({ children, initialJboId, theme }) => {
  // State management for user data
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [goldPlanEnable, setGoldPlanEnable] = useState(false);
  const [ShippingEnable, setShippingEnable] = useState(false);
  const [errorLoading,setErrorLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [jboId, setJboId] = useState(initialJboId);
  const [themeState, setTheme] = useState(theme);
  // state to store menu data for thumbnail status in breadcrumbs - 05/08/2024
  const [menuData,setMenuData]=useState([]);
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [goldRate, setGoldRate] = useState();
  const [logoUrl, setLogoUrl] = useState("");
  const [domainData, setDomainData] = useState({});
  const [featureCustomizations, setFeatureCustomizations] = useState({});
  const [metalCategories, setMetalCategories] = useState({});
  const [menuJsonApp, setMenuJsonApp] = useState({});
  const [errorImages, setErrorImages] = useState({
    pageNotFound: '',
    somethingWentWrong: '',
    noDataFound: '',
    productNotFound: ''
  });
  // const [productSliderConfig, setProductSliderConfig] = useState();

  // Effect to sync state with localStorage and cookies
  useEffect(() => {
    const localUserId = localStorage.getItem('userId');
    const cookieUserId = getCookie('userId');
    if (localUserId !== userId) {
      setUserId(localUserId || cookieUserId);
    }

    const localToken = localStorage.getItem('token');
    const cookieToken = getCookie('token');
    if (localToken !== token) {
      setToken(localToken || cookieToken);
    }

    const localUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    const cookieUserDetails = getCookie('userDetails') ? JSON.parse(getCookie('userDetails')) : null;
    if (localUserDetails !== userDetails) {
      setUserDetails(localUserDetails || cookieUserDetails);
    }
  }, []);

  // Effect to update localStorage and cookies when state changes
  useEffect(() => {
    localStorage.setItem('userId', userId);
    localStorage.setItem('token', token);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    setCookie('userId', userId, 30);
    setCookie('token', token, 30);
    setCookie('userDetails', JSON.stringify(userDetails), 30);
  }, [userId, token, userDetails]);

  // Function to clear user data from state, localStorage, and cookies
  const clearUser = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    deleteCookie('userId');
    deleteCookie('token');
    deleteCookie('userDetails');
    setUserId('');
    setToken(null);
    setUserDetails(null);
  };

  // Providing the user context to child components
  return (
    <UserContext.Provider value={{
      userId, jboId, logoUrl, errorLoading,menuData,setMenuData,setErrorLoading,setLogoUrl,ShippingEnable,errorImages,setShippingEnable,setErrorImages, setJboId, theme: themeState, setTheme, setUserId,
      goldPlanEnable, setGoldPlanEnable, token, setToken, userDetails, setUserDetails, clearUser,
      goldRate, setGoldRate, domainData, setDomainData, featureCustomizations, setFeatureCustomizations,
      metalCategories, setMetalCategories, menuJsonApp, setMenuJsonApp
    }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
