'use client'

import axios from 'axios';
import React, { useState, useEffect } from "react";
import '../styles/globals.css';
import 'swiper/swiper.min.css';
import Head from "next/head";
import Layout from '../components/Layouts/Layout';
import { CurrencyProvider } from '../contexts/CurrencyContext';
import { JboProvider } from '../contexts/ContextJbo';
import { ThemeProvider } from '../contexts/ThemeContext';
import { CartProvider } from '../contexts/CartContext';
import { WishlistProvider } from '../contexts/WishlistContext';
import { UserProvider } from '../contexts/UserContext';
import { useRouter } from 'next/router';
import ErrorBoundary from '../components/ErrorBoundary';
import Empty from './Empty';
import { setCookie, getCookie } from '../utils/cookie';
import '/public/fontawesome/css/all.min.css'

// Modal.setAppElement('#root'); // Set the root element for accessibility

function transformLiveData(inputData) {

  return {
    primary: inputData.primary_colour,
    secondary: inputData.secondary_colour,
    alternative: inputData.alternative_colour,
    font: inputData.primary_font,
    secondaryFont: inputData.secondary_font,
    alternativeFont: inputData.alternative_font,
    title: "Default Title",
    content: "Default Content",
    currency: "Default Currency",
    token: inputData.token,
    siteStatus: inputData.site_status,
    splashLogo: inputData.spalsh_logo,
    favicon: inputData.fav_icon
  };
}

function transformData(inputData) {

  return {
    primary: inputData.primary_color,
    secondary: inputData.secondary_color,
    alternative: inputData.alternative_color,
    font: inputData.primary_font,
    secondaryFont: inputData.secondary_font,
    alternativeFont: inputData.alternative_font,
    title: "Default Title",
    content: "Default Content",
    currency: "Default Currency",
    token: inputData.token,
    siteStatus: inputData.site_status,
    splashLogo: inputData.spalsh_logo,
    favicon: inputData.fav_icon
  };
}


function processPropsData(products) {
  if (products) {
    let previewData = {};
    let liveData = {};


    // Determine hostname to conditionally process products
    const hostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : window.location.hostname;

    let _transformedData = null
    let _jboId = null
    if (!hostname.startsWith('preview')) {

      if (Array.isArray(products)) {
        products.forEach(item => {
          if (item.site_status === 0) {
            previewData = item;
          } else if (item.site_status === 1) {
            liveData = item;
          }
        });
        _jboId = JSON.stringify(liveData.jbo_id)
        setCookie('jb_id', _jboId, 7);
        _transformedData = JSON.stringify(transformLiveData(liveData));


        setCookie('productsData', _transformedData, 7);
      } else {
        _jboId = JSON.stringify(products.jbo_id)
        setCookie('jb_id', _jboId, 7);
        _transformedData = JSON.stringify(transformData(products))

        setCookie('productsData', _transformedData, 7);
      }
      // debugger

    } else {
      _jboId = JSON.stringify(products.jbo_id)
      setCookie('jb_id', _jboId, 7);
      _transformedData = JSON.stringify(transformData(products));

      setCookie('productsData', _transformedData, 7);
    }
    return { productsData: _transformedData, jboId: _jboId }
  }
  else {
    return null
  }

}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [localProductsData, setLocalProductsData] = useState(null);
  const [jboIdData, setJboIdData] = useState();
  const [showChild, setShowChild] = useState(false);
  const [favicon, setFavicon] = useState('');
  // states for google modules - 21/08/2024
  const [trackingId, setTrackingId] = useState(null);
  const [tagManagerId, setTagManagerId] = useState(null);
  const [googleVerificationId, setGoogleVerificationId] = useState(null);

  // const logError = (endpoint, error) => {
  //   const responseData = error.response?.data ?? '';
  //   errorLogApi({
  //     job_id: "",
  //     endpoint: endpoint,
  //     payload: '',
  //     responseData: responseData,
  //     errorMsg: error
  //   });
  // };


  useEffect(() => {

    let productsData = null
    let jboId = null

    if (pageProps.products) {
      const out = processPropsData(pageProps.products)
      productsData = out.productsData
      jboId = out.jboId
    }

    const storedProductsData = getCookie('productsData') ?? productsData

    setShowChild(true);
    // const storedProductsData = getCookie('productsData');
    const storedJboId = getCookie('jb_id') ?? jboId;

    if (storedProductsData) {
      const parsedData = JSON.parse(storedProductsData);
      const parsedJboId = JSON.parse(storedJboId);
      setJboIdData(parsedJboId);
      setLocalProductsData(parsedData);
      setFavicon(parsedData.favicon);
    }

   // Google Tag manager & Google verification Api implementation via api
   if (typeof window !== 'undefined') {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const currentHostname = window.location.hostname === "localhost" ? process.env.NEXT_PUBLIC_WEB_HOST : parsedUrl.hostname;

    const payload = {
      data: JSON.stringify({ jbo_id: storedJboId })
    };
    axios.post(`https://${currentHostname}/api/settings/viewGoogleIntegration`, payload)
      .then(response => {
        const { google_analytics, google_tag_manager, google_verification } = response.data;

        if (google_analytics && google_analytics.length > 0) {
          setTrackingId(google_analytics[0].analytics_tracking_id);
        }

        if (google_tag_manager && google_tag_manager.length > 0 && google_tag_manager[0].status === 1) {
          setTagManagerId(google_tag_manager[0].google_tag_manager_id);
        }

        if (google_verification && google_verification.length > 0 && google_verification[0].status === 1) {
          setGoogleVerificationId(google_verification[0].google_verification_id);
        }
      })
      .catch(error => {
        console.error('Error fetching Google Integration data:', error);
      });
  }
  }, []);

  // Google tag manager implementation - 17/08/2024 
  useEffect(() => {
    if (tagManagerId) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerId}`;
      script.async = true;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }
  }, [tagManagerId]);

  const excludedRoutes = ['/auth/SignIn', '/auth/Register', '/auth/ForgotPassword', 'user/forgotpsd'];
  const isExcludedRoute = excludedRoutes.some((route) => router.pathname.startsWith(route));
  const isSitemap = router.pathname === '/sitemap.xml';

  if (!showChild) {
    return null;
  }

  let content;
  if (isSitemap) {
    content = <Component {...pageProps} />;
  } else if (isExcludedRoute) {
    content = <Component {...pageProps} />;
  } else {
    content = (
      <>
        {!jboIdData && <Empty />}
        <Layout>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </>
    );
  }

  let primary_font = "Poppins"
  let secondary_font = "Roboto"


  if (pageProps.products) {
    primary_font = pageProps?.products[0]?.primary_font ? pageProps?.products[0]?.primary_font : pageProps?.products?.primary_font ?? 'Poppins'
    secondary_font = pageProps?.products[0]?.secondary_font ? pageProps?.products[0]?.secondary_font : pageProps?.products.secondary_font ?? 'Roboto'
  }


  const encodedPrimaryFont = encodeURIComponent(primary_font);
  const encodedSecondaryFont = encodeURIComponent(secondary_font);

  const fontFamilies = [
    "Poppins:wght@400;600",
    "Roboto:wght@400;700",
    "Open+Sans:wght@400;700",
    "Lato:wght@400;700",
    "Oswald:wght@400;700",
    "Slabo+27px:wght@400;700",
    "Roboto+Condensed:wght@400;700",
    "Montserrat:wght@400;700",
    "Source+Sans+Pro:wght@400;700",
    "Raleway:wght@400;700",
    "PT+Sans:wght@400;700",
    "Great+Vibes:wght@400;700",
    "Playfair+Display:wght@400;700",
    "Cantata+One:wght@400;700",
    "Roboto+Slab:wght@400;700",
    "Cardo:wght@400;700",
    "Merriweather:wght@400;700",
    "Lora:wght@400;700",
    "Domine:wght@400;700",
    "Karla:wght@400;700",
    "Crimson+Text:wght@400;700",
    "EB+Garamond:wght@400;700",
    "Zilla+Slab:wght@400;700",
    "Josefin+Slab:wght@400;700",
    "Unna:wght@400;700",
    "Abhaya+Libre:wght@400;700",
    "Ubuntu:wght@400;700",
    "Rubik:wght@400;700",
    "Cabin:wght@400;700",
    "Heebo:wght@400;700",
    "Barlow:wght@400;700",
    "Archivo+Narrow:wght@400;700",
    "Asap:wght@400;700",
    "Ropa+Sans:wght@400;700",
    "Pacifico:wght@400;700",
    "Sue+Ellen+Francisco:wght@400;700",
    "Nothing+You+Could+Do:wght@400;700",
    "Lobster:wght@400;700",
    "Mountains+of+Christmas:wght@400;700",
    "Squada+One:wght@400;700",
    "Bai+Jamjuree:wght@400;700",
    "Comfortaa:wght@400;700",
    "Cousine:wght@400;700",
    "Exo:wght@400;700",
    "Muli:wght@400;700",
    "Courgette:wght@400;700",
    "Patua+One:wght@400;700",
    "Roboto+Mono:wght@400;700",
    "Nanum+Gothic+Coding:wght@400;700",
    "Anonymous+Pro:wght@400;700",
    "Cutive+Mono:wght@400;700",
    "Source+Sans+Pro:wght@400;700",
    "Sansita+One:wght@400;700",
    "Fira+Sans:wght@400;700",
    "Vast+Shadow:wght@400;700",
    "Droid+Serif:wght@400;700"
  ];

  const primaryFontUrl = fontFamilies.find(family => family.includes(encodedPrimaryFont)) || encodedPrimaryFont;
  const secondaryFontUrl = fontFamilies.find(family => family.includes(encodedSecondaryFont)) || encodedSecondaryFont;

  const googleFontsUrlP = `https://fonts.googleapis.com/css2?family=${primaryFontUrl}&family=${secondaryFontUrl}&display=swap`;
  // const googleFontsUrlS = `https://fonts.googleapis.com/css2?family=${}&display=swap`;

  return (
    <div>
      <Head>
            {/* google site verfication - 20-08-2024 */}
      {router.pathname === '/' && googleVerificationId && (
          <meta name="google-site-verification" content={googleVerificationId} />
        )}
        <link rel="icon" href={favicon} />
      {/*  // Google tag manager script implementation -20-08-2024 */}
        {trackingId && (
          <>
            
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`} />
            <script dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${trackingId}');
              `,
            }} />
          </>
        )}
        {/* Include the Google Fonts */}
        <link href={googleFontsUrlP} rel="stylesheet" />
        {/* <link href={googleFontsUrlS} rel="stylesheet" /> */}
      </Head>

      <ThemeProvider theme={localProductsData}>
        <CurrencyProvider>
          <JboProvider initialJboId={jboIdData}>
            <UserProvider>
              <CartProvider>
                <WishlistProvider>
                  {content}
                </WishlistProvider>
              </CartProvider>
            </UserProvider>
          </JboProvider>
        </CurrencyProvider>
      </ThemeProvider>
    </div>
  );
}

export default MyApp;
