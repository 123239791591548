import React, { useState, useEffect, useContext, useRef } from "react";
import Link from "../../components/custom/Link";
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { FaExclamationCircle } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import Header from "./Header";
import { useRouter } from "next/router";
import { ThemeContext } from "../../contexts/ThemeContext";
// Assuming your component file is in the same directory as the hook
import useMatchMedia from "../custom/ useMatchMedia";
import { AiOutlineDown, AiOutlineRight, AiOutlineLeft } from "react-icons/ai"; // Import icons from react-icons
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";

import { motion, AnimatePresence } from "framer-motion"; // Import from framer-motion

import { useAxios } from "../../utils/axios";
import axios from "axios";
import * as Sentry from "@sentry/react";
import MobileSearchBar from "../Search/MobileSearchBar";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { UserContext } from "../../contexts/UserContext";

const Navbar = () => {
  // State and context initialization
  //  Simple Menu
  const { currentCurrency, setCurrentCurrency, currencyData, setCurrencyData } =
    useContext(CurrencyContext);
  const { menuJsonApp, userDetails, setShippingEnable, setGoldPlanEnable } =
    useContext(UserContext);
  const [showCategories, setShowCategories] = useState(false);
  const [GoldPlan, setGoldPlan] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rates, setRates] = useState([]);
  const [error, setError] = useState(null); // State for handling errors
  const router = useRouter(); // Next.js router hook
  const isMediumScreen = useMatchMedia(
    "(min-width: 1024px) and (max-width: 1200px)"
  );
  const isMediumLargeScreen = useMatchMedia(
    "(min-width: 1201px) and (max-width: 1439px)"
  );
  const isLargeScreen = useMatchMedia("(min-width: 1440px)");
  const theme = useContext(ThemeContext);
  const [subMenuPosition, setSubMenuPosition] = useState("left-full");
  const { isMobile, isTablet } = useDeviceSize();
  const divRefheight = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  // Function to truncate string with ellipsis
  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  useEffect(() => {
    if (divRefheight.current) {
      setDivHeight(divRefheight.current.clientHeight);
      //console.log("divRefHeight: " + divRefheight.current.clientHeight)
    }
  }, [divRefheight?.current?.clientHeight]);

  // Ref for the dropdown menu
  const divRef = React.useRef(null);

  // Effect for handling outside clicks for dropdowns
  useEffect(() => {
    // Function to check if clicked outside of dropdown
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowCategories(false);
        setActiveCategory(null);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const axiosInstance = useAxios();

  useEffect(() => {
    // Make sure menuJsonApp is available and not empty
    if (menuJsonApp && Object.keys(menuJsonApp).length !== 0) {
      // Handle the menu data
      setCategoriesData(menuJsonApp.menu_list);
      setGoldPlan(menuJsonApp.savings_scheme_enable === 1 ? true : false);
      setGoldPlanEnable(menuJsonApp.savings_scheme_enable === 1 ? true : false);
      // Shipping status
      setShippingEnable(menuJsonApp.shipping_enable === 1 ? true : false);

      // Filter out Gold 91.6 (22ct) and Silver 99.9 if metal_list is not null
      if (menuJsonApp.metal_list) {
        const filteredMetalList = menuJsonApp.metal_list.filter((metal) => {
          return (
            metal.metal_name === "Gold 91.6 (22ct)" ||
            metal.metal_name === "Silver 99.9"
          );
        });
        setRates(filteredMetalList);
      } else {
        setRates([]);
      }
      setIsLoading(false);
    } else {
      // If menuJsonApp is not available or empty, handle the error or set loading state
      setIsLoading(true); // Set loading state
      // You can handle error or use Sentry.captureException
    }
  }, [menuJsonApp]);

  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const [hoveredItem, setHoveredItem] = useState(null);

  const variants = {
    enter: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: { duration: 0.5, ease: "easeInOut" }, // smoother transition
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      x: -50,
      transition: { duration: 0.3, ease: "easeInOut" }, // quicker exit
    },
  };
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2, // Increase stagger time for a smoother entrance
      },
    },
  };
  const rowVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        x: { type: "spring", stiffness: 100, damping: 12 }, // Spring physics for movement
        opacity: { duration: 0.5, ease: "easeInOut" }, // Smooth the opacity transition
      },
    },
  };
  // Functions for handling submenu and child menu interactions

  // Function to handle mouse enter on main menu items
  const handleSubMenuHover = (id) => {
    setActiveSubMenu(id);
  };

  // Function to handle mouse enter on sub-menu items
  const handleChildMenuHover = (id) => {
    const submenuElement = document.getElementById(`submenu-${id}`);
    const rect = submenuElement.getBoundingClientRect();
    const availableSpaceToRight = window.innerWidth - rect.right;
    const openMenuModel =
      availableSpaceToRight > 700
        ? "md:left-full md:top-0"
        : "md:right-full md:top-0";

    setSubMenuPosition(openMenuModel);
    setHoveredItem(id);
  };

  // Function to reset the active sub-menu
  const resetActiveMenus = () => {
    setActiveSubMenu(null);
    setHoveredItem(null);
  };
  // Calculate maximum children count in categories for dynamic rendering
  const maxChildCount = Math.max(
    ...categoriesData.map((menu) =>
      Math.max(...menu.child.map((submenu) => submenu.child.length))
    )
  );

  // JSX for Navbar component
  return (
    <>
      <Header rates={rates} loading={isLoading} />
      {/* {isMobile && <div className="top-12 z-50 py-1 shadow-sm w-full text-sm sticky bg-white transform translate-y-0">
        <MobileSearchBar />
      </div>} */}
      <div className="bg-secondary hidden lg:block sticky top-0 z-50">
        <nav className="xl:container">
          <div className="w-full mx-auto">
            <div className="flex">
              <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between flex-grow xl:gap-2 ">
                <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-x-6 text-base capitalize">
                  <ul
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="flex justify-start items-center"
                  >
                    {categoriesData
                      .slice(0, 6)
                      .map((menu, index) => {
                        const isLastItem = index === categoriesData.length - 1;
                        const isOneOfLastTwo =
                          index >= categoriesData.length - 2;
                        return (
                          <div className="relative">
                            <li
                              key={menu.id}
                              variants={rowVariants}
                              className="relative"
                            >
                              {/* New div wrapper for mouse leave */}
                              <div onMouseLeave={resetActiveMenus}>
                                <button
                                  whileHover={{ scale: 1.03 }} // Slight grow effect on hover
                                  whileTap={{ scale: 0.97 }} // Slight shrink effect on tap/click
                                  onMouseEnter={() =>
                                    handleSubMenuHover(menu.id)
                                  } //Main categories which is on top in row
                                  className="flex items-center capitalize p-4"
                                >
                                  <span className="text-alternative hover:text-primary transition lg:text-[12px] xl:text-[16px] font-semibold">
                                    {menu.name}
                                  </span>

                                  <MdKeyboardArrowDown
                                    className={`ml-2 text-[17px] xl:text-xl `}
                                  />
                                </button>
                                <AnimatePresence>
                                  {activeSubMenu === menu.id && ( //Main categories(handleSubMenuHover) -> subCategories which opens in col menus
                                    <div //should calculate this div height
                                      ref={divRefheight}
                                      initial="exit"
                                      animate="enter"
                                      exit="exit"
                                      variants={variants}
                                      transition={{ duration: 0.2 }}
                                      className="absolute left-0 xl:w-72 xl:px-2 bg-white border border-gray-200 shadow-lg divide-y divide-gray-300 divide-dashed"
                                    >
                                      {menu.child.map((submenu) => {
                                        // Calculate columns based on child menu length
                                        // Calculate columns based on child menu length but limit to max 4 columns
                                        // Adjust column count based on the number of children in the menu
                                        let columnCount;
                                        if (menu.child.length < 4) {
                                          if (menu.child.length == 1) {
                                            columnCount = 1;
                                          } else {
                                            columnCount = 2;
                                          }
                                        }
                                        // else if (menu.child.length == 1) {
                                        //   columnCount = 1;
                                        // }
                                        else {
                                          // Calculate columns based on child menu length but limit to max 4 columns
                                          columnCount = Math.ceil(
                                            submenu.child.length /
                                              menu.child.length
                                          );
                                          columnCount =
                                            columnCount > 3 ? 3 : columnCount;
                                        }

                                        const heightPerItem = 40;
                                        const totalHeight =
                                          menu.child.length * heightPerItem;
                                        const heightStyle = `${totalHeight}px`;

                                        // Determine if the content overflows the height limit
                                        // Determine the maximum word count across all childMenu names in this submenu

                                        const maxWordCount =
                                          submenu.child.every(
                                            (childMenu) =>
                                              childMenu.name.length > 15
                                          );
                                        const isOverflowingHeight =
                                          submenu.child.length > 40;

                                        // Determine minWidth based on all childMenu names length
                                        let minWidth = "350px"; // Default minWidth

                                        if (maxWordCount) {
                                          minWidth = "600px"; // Increase minWidth if every name is longer than 15 characters
                                        } else if (submenu.child.length >= 15) {
                                          minWidth = ""; // Use default styling if there are 15 or more children
                                        }
                                        const gridStyle = {
                                          display: "grid",
                                          gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
                                          // height: heightStyle,
                                          // minWidth: minWidth,
                                        };

                                        // Conditionally apply scrollbar classes based on height overflow
                                        const scrollbarClasses =
                                          isOverflowingHeight
                                            ? "scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-blue-100 overflow-x-auto"
                                            : "";

                                  return (
                                    <div key={submenu.id} >
                                    <div
          key={submenu.id}
          onMouseEnter={() => handleChildMenuHover(submenu.id)}
          className={`flex justify-between items-center w-full px-6 py-3 text-left text-alternative ${
            activeSubMenu === submenu.id ? 'border-indigo-500' : ''
          }`}
        >
          {submenu.status === 1 ? (
            <Link href={`${(submenu.id && submenu.url) || ''}`} className="inline-block">
              <span
                id={`submenu-${submenu.id}`}
                className={`w-40 uppercase ${
                  hoveredItem === submenu.id ? 'text-primary font-semibold' : 'text-alternative'
                }`}
                title={submenu.name}
              >
                {submenu.name}
              </span>
            </Link>
          ) : (
            <span
              id={`submenu-${submenu.id}`}
              className={`w-40 uppercase ${
                hoveredItem === submenu.id ? 'text-primary font-semibold' : 'text-alternative'
              }`}
              title={submenu.name}
            >
              {submenu.name}
            </span>
          )}
          <i className="fas fa-chevron-right text-gray-500"></i>
        </div>
                                  {hoveredItem === submenu.id && ( //this is detail menu div card - set this below div height to theits parent height
                                  <div className={`absolute min-w-max bg-white border border-gray-200 shadow-lg z-20 ${ subMenuPosition } ${scrollbarClasses}`} style={{ minHeight: `${divHeight}px` }}>
                                    <h1 className="text-primary uppercase font-semibold text-center px-5 py-2 pt-3">{submenu.name}</h1>
                                    <div
                                      initial="exit"
                                      animate="enter"
                                      exit="exit"
                                      variants={variants}
                                      transition={{ duration: 0.2 }}
                                      style={{
                                        ...gridStyle,
                                        gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
                                      }}
                                      >
                                      {submenu.child.map((childMenu) => (
                                        <a
                                          key={childMenu.id}
                                          href={`${childMenu.url}${theme.siteStatus === 0  ? `?pid=${theme.token}` : ''}`}
                                          className="text-alternative hover:bg-gray-50 px-4 py-2 h-10"
                                        >
                                        <span className="text-alternative" title={childMenu.name}>
                                          {childMenu.name}
                                        </span>
                                      </a>
                                      ))}
                                    </div>
                                  </div>
                                  )}
                                </div>
                                  );
                                })}
                              </div>
                            )}
                          </AnimatePresence>
                        </div>
                      </li>
                      </div>
             
                    );
                  }).concat(
                    GoldPlan
                      ? [
                        <li key="goldPlan" className="relative">
                        <div onMouseLeave={resetActiveMenus}>
                          <button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.97 }}
                            className="flex items-center capitalize group" // Added group class for targeting children on hover
                          >
                            <div className="flex items-center text-alternative hover:text-primary transition">
                              
                              <Link href={
    theme.siteStatus === 0 
      ? `/GoldPlan/PlanList?pid=${theme.token}`
      : `/GoldPlan/PlanList`
  } className="lg:text-[12px] xl:text-[18px] font-semibold">
                           <h1>     Gold Plan</h1>
                              </Link>
                              {/* <FaCrown className="ml-2 text-lg"/> */}
                            </div>
                          </button>
                        </div>
                      </li>
                        ]
                      : []
                  )}
                </ul>
              </div>
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between sm:w-auto w-full mt-2 sm:mt-0">
               
{/* 
                <div className="relative  inline-block mt-2 sm:mt-0">
                  
                  <select
                    value={currentCurrency}
                    onChange={(e) => {
                      setCurrentCurrency(e.target.value);
                      router.push(
                        {
                          pathname: router.pathname,
                          query: { ...router.query, currency: e.target.value },
                        },
                        undefined,
                        { scroll: false }
                      );
                    }}
                    style={{
                      border: "none",
                      borderRadius: "none",
                      focus: "none",
                    }}
                    className="w-full text-primary font-semibold px-4 py-2 border pr-8 border-gray-300 bg-transparent shadow-sm rounded
focus:ring-primary focus:border-primary"
                  >
                    {currencyData &&
                      Object.entries(currencyData).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value.name}
                        </option>
                      ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-primary"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
