import React,{useState,useEffect,useContext} from 'react'
import Image from "next/image";
import Link from "next/link";
import logo from "../../public/images/whitelogo.png";
import Modal from "react-modal";
import styles from "../../styles/style.module.css"
import axios from 'axios';
import { ThemeContext } from '../../contexts/ThemeContext';
import { UserContext } from "../../contexts/UserContext";
import * as Sentry from "@sentry/react";

import { BUILDER_URL, setImageSrc } from '../../utils/config';
Modal.setAppElement("#__next"); 
// Custom styles for the modal component

// Base URL for API endpoint, fetched from environment variables
const BASE_URL = BUILDER_URL;
/**
 * Footer component of the application.
 * 
 * This component fetches footer data from an API and displays it. It also includes
 * a modal that can be opened and closed, with custom styling.
 *
 * @returns {React.ReactElement} - The Footer component.
 */
const Footer = () => {
     // State for storing footer data and modal state
  const [footerData, setFooterData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useContext(UserContext); 
  // Accessing the theme context
  const theme = useContext(ThemeContext);

  // Getting the current year for dynamic display in the footer
  const currentYear = new Date().getFullYear();
  const token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjI0OSwic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxMDU5MzYxOX0.M5anhjEb3RnZazpO5b-pMKJJKCT78OM6Gjag7lPDQ3Y"
  // useEffect to fetch footer data on component mount and when theme changes
  useEffect(() => {
    axios.get(`${BASE_URL}/footer`, {
        headers: { 'Authorization': `Bearer ${theme.token}` }
      })
      .then((response) => {
        // Setting footer data if response is valid
        if (response.data && response.data.length > 0) {
          setFooterData(response.data[0]);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);  // Logging error if API call fails
      });
  }, [theme]); // Dependency array includes theme
  
  
  
  return (
    <>
{/* Start Footer */}
<footer className='bg-secondary pt-8 pb-12 lg:pb-0 border-t border-gray-100'>
  <div className="container mx-auto px-4 lg:px-6">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

      {/* Left section with logo and s'ocial media icons */}
      <div className='space-y-8 md:ml-10'>
        {/* Logo */}
        {/* <h1>Footer4</h1> */}
        {/* footer logo ui logic implemented -20/08/2024 */}
        {footerData?.logo_url && <div className="flex justify-center md:justify-start">
          <img src={setImageSrc(footerData?.logo_url, '')} alt="logo" className="object-contain" width={180} height={150} />
        </div> }
        

        {/* Description */}
        <div className="text-center md:text-left">
          <p className='text-alternative text-base'>{footerData?.footer_description}</p>
        </div>

        {/* Social Media Icons */}
        <div className="flex justify-center md:justify-start space-x-5">
          {footerData?.social_media?.filter(media => media.is_enabled === 1)?.map(media => (
            <Link key={media.social_media_id} href={media.social_media_url} rel="nofollow">
              <span className="w-6 h-6 rounded-full flex items-center justify-center border border-gray-300 hover:text-primary">
                <img 
                  className="w-full h-full object-cover"
                  src={setImageSrc(media.social_media_master.social_media_image, '')} 
                  alt={media.social_media_master.social_media_name}
                  style={{ aspectRatio: '1 / 1' }}
                />
              </span>
            </Link>
          ))}
        </div>
      </div>

      {/* Footer links */}
      <div className='space-y-8 md:col-span-2 md:gap-8'>
        <div className='md:grid md:grid-cols-2 lg:grid-cols-4'>
          {/* Dynamically create footer links columns */}
          {footerData?.footer_menu?.map(menu => (
            <div key={menu.footer_menu_id}>
              <h3 className='text-sm font-semibold text-alternative  text-center lg:text-left mt-8 tracking-wide uppercase'>{menu.footer_menu_title}</h3>
              <ul className='mt-4 space-y-4 capitalize  text-center lg:text-left '>
                {menu.AboutUsPage && (
                <li>
                  <Link
                    href={
                      theme.siteStatus === 0 
                        ? `/AboutUs?pid=${theme.token}`
                        : `/AboutUs`
                    }
                  >
                    {menu.AboutUsPage.title}
                  </Link>
                </li>
                )}
                {menu?.ContactUsPage && (
                  <li>
                    <Link
                      href={
                        theme.siteStatus === 0 
                          ? `/ContactUs?pid=${theme.token}`
                          : `/ContactUs`
                      }
                    >
                      {menu.ContactUsPage.title}
                    </Link>
                  </li>
                )}
                       {menu?.custom_page?.map((cp) => {
  const slug = cp.custom_page_title.replace(/ /g, '-'); // Replace spaces with hyphens -20/08/2024
  return (
    <li key={cp.custom_page_id}>
      <Link
        href={
          theme.siteStatus === 0
            ? `/${slug}?cid=${cp.custom_page_id}&pid=${theme.token}`
            : `/${slug}?cid=${cp.custom_page_id}`
        }
      >
        {cp.custom_page_title}
      </Link>
    </li>
  );
})}

              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>

 

  </div>
  {/* Payment cards at the bottom */}
  {
    footerData?.copyright_content && (
      <div className='bg-primary py-4 mt-8 lg:mt-12'>
      <div className='container mx-auto flex flex-col sm:flex-row items-center justify-between'>
        <p className='text-white font-semibold text-center sm:text-left mb-4 sm:mb-0'>
          ⓒ {footerData?.copyright_content}
        </p>
        <div className='flex justify-center gap-4'>
          {footerData?.card?.filter(card => card.is_enabled === 1)?.map(card => (
            <div key={card.card_id} className="flex justify-center items-center w-12 p-1 h-8 lg:p-2 bg-white rounded-lg">
              <img
                src={setImageSrc(card.pay_card_master.card_image, '')} 
                alt={card.pay_card_master.card_name} 
                width={65} 
                height={40}
                className="object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    )
  }

</footer>

{/* End Footer */}

{/* Start Copyright */}


{/* End Copyright */}
    </>
  )
}

export default Footer