// Importing React library
import React, { useState, useEffect } from 'react';
// Importing components used in the layout
import Header from './Header';
import Footer from './Footer';
import MainNavBar from './MainNavBar';
import MobileMenu from './MobileMenu';

/**
 * Layout component that wraps the main content of the application.
 * 
 * This component is used as a layout wrapper for the application. It includes
 * the header, main navigation bar, and footer, which are common across various
 * pages. It also includes a mobile menu component for improved mobile user experience.
 * The children props are used to render the main content of the page.
 *
 * @param {Object} props - The props passed to the component.
 * @param {React.ReactNode} props.children - The content to be displayed in the main area of the layout.
 * @returns {React.ReactElement} - The complete layout of the application.
 */
const Layout = ({ children }) => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    // This effect runs after the component mounts
    // Set contentLoaded to true when the DOM content is fully loaded

    // Delay the rendering of the footer by 1 second
    const timeoutId = setTimeout(() => {
      setShowFooter(true);
    }, 3000);
    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
     
        {/* Render the Header component only if contentLoaded is true */}
        <Header />

        <MainNavBar />
        {/* Render the main content passed as children */}
        <div className="stack">
          {children}
          {/* <Loading /> */}
        </div>
        {/* Render the Footer component only if contentLoaded is true */}
        <Footer />
        <MobileMenu />
    
    </>
  );
};

// Exporting the Layout component for use in other parts of the application
export default Layout;
