import react, {useState, useRef, useEffect,useContext} from 'react';
import { useRouter } from 'next/router';
import {ThemeContext} from "../../contexts/ThemeContext";
import { UserContext } from '../../contexts/UserContext';
const DeskTopSearchBar = () => {
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef();
    const router = useRouter();
    const theme = useContext(ThemeContext);
    const { featureCustomizations, metalCategories } = useContext(UserContext);
    const [searchKey, setSearchKey] = useState([]);
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const handleSearch = (e) => {
        e.preventDefault();
        const searchUrl = theme.siteStatus === 0  ? `/search/${searchValue}?pid=${theme.token}` : `/search/${searchValue}`;
        router.push(searchUrl);
        // window.location.href=searchUrl;
        setSearchValue('');
        inputRef.current.blur();
      };
    const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSearch(e);
    }
    };

    useEffect(() => {
        if (featureCustomizations && featureCustomizations.is_search_key_enabled == 1 && featureCustomizations.search_key)  {
          setSearchKey(featureCustomizations.search_key);
        } else {
            getCategoryNames(metalCategories)
        }
      }, [featureCustomizations, metalCategories]);

      const getCategoryNames = (names) => {
        var totalItems = [];
        if (!Array.isArray(names)) {
            return;
        }
        names.forEach(item => {
            totalItems.push(item.name);
            if (item.child) {
                item.child.forEach(subItem => {
                    // totalItems.push(subItem.name);
                    if (subItem.child) {
                        subItem.child.forEach(innerItem => {
                            totalItems.push(innerItem.name);
                        });
                    }
                });
            }
        });
        setSearchKey(totalItems);
    };

      const cycleProducts = () => {
        setCurrentProductIndex(Math.floor(Math.random() * searchKey.length));
    };

    useEffect(() => {
        const interval = setInterval(cycleProducts, 2000);
        return () => clearInterval(interval);
    }, [searchKey]);
    
    return (
        <div className="w-full md:flex justify-center items-center  md:w-[40%] lg:w-[40%] hidden my-4">
        <input
            type="text"
            className="pl-4 w-full border border-r-0 border-primary py-3 focus:ring-primary  h-14"
            placeholder={
                searchKey && searchKey[currentProductIndex]
                  ? `Search for ${searchKey[currentProductIndex]}`
                  : 'Search'
              }
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={handleKeyPress}
            ref={inputRef}
        />
        <button
            type="submit"
            className="bg-primary border border-primary text-white px-6 font-medium hover:bg-transparent hover:text-primary transition h-14"
            onClick={handleSearch}
        >
            <i className="fas fa-search"></i>
        </button>
    </div>
    
    );
};

export default DeskTopSearchBar;
