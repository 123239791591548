import React from 'react';
import PhoneIcon from './PhoneIcon';
import PhoneIconTwo from './PhoneIconTwo';
import ChatIcon from './ChatIcon';
import { useJobId } from '../../contexts/ContextJbo';

const ContactUs = () => {
    const { jboId } = useJobId();
    return (
        <div>
            {/* Chat Icon */}
            <ChatIcon />
            {/* Phone Number Icon */}
            {jboId === 233 ? <PhoneIconTwo/> :<PhoneIcon/>}
          
        </div>
    );
};

export default ContactUs;
