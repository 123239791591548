import React, {useContext, useState, useEffect} from "react";
import Fab from '@mui/material/Fab';
import { UserContext } from '../../contexts/UserContext';
import {useDeviceSize} from "../../utils/useDeviceSize";

const phoneIcon = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [phoneNo, setPhoneNo] = useState('');
    const { isMobile, isTablet } = useDeviceSize();
    useEffect(() => {
        if (featureCustomizations.mobile_number)  {
            setPhoneNo(featureCustomizations.mobile_number);
        }
      }, [featureCustomizations]);
    return (
        <>
        { (isMobile || isTablet) ?         
        <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '50px', right: '65px', zIndex:'9999', width: '40px', height: '40px' }}>
            <a
                href={`tel:${phoneNo}`}
                className="p-2 lg:p-3 hover:text-primary transitio rounded-full shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
                <i className="fas fa-phone-alt text-lg"></i>
            </a>
        </Fab> : 
        <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '80px', zIndex:'9999', width: '50px', height: '50px' }}>
            <a
                href={`tel:${phoneNo}`}
                className="p-2 lg:p-3 hover:text-primary transitio rounded-full shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
                <i className="fas fa-phone-alt text-xl lg:text-1xl"></i>
            </a>
        </Fab>}

        </>
        

    )
};

export default phoneIcon;