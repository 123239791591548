import React, { useState, useEffect, useContext } from "react";
import Link from "../../components/custom/Link";
import {ThemeContext} from "../../contexts/ThemeContext";
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { UserContext } from "../../contexts/UserContext";
import { FaExclamationCircle } from "react-icons/fa";
import Header from "./Header";
import { BASE_URL } from '../../utils/config';
import axios from "axios";
import { useRouter } from "next/router";
import { FaCrown } from 'react-icons/fa'; 
// Assuming your component file is in the same directory as the hook
import useMatchMedia from '../custom/ useMatchMedia';
import MobileSearchBar from '../Search/MobileSearchBar';
import { useDeviceSize } from '../../utils/useDeviceSize';


import { useAxios } from "../../utils/axios";

const Navbar = () => {
 // State and context hooks for managing various states and data
 const { currentCurrency, setCurrentCurrency, currencyData, setCurrencyData } =
 useContext(CurrencyContext);
 const { setGoldPlanEnable, menuJsonApp,userDetails,setShippingEnable } = useContext(UserContext); 
const [showCategories, setShowCategories] = useState(false);
const [GoldPlan, setGoldPlan] = useState(false);
const [activeCategory, setActiveCategory] = useState(null);
const [categoriesData, setCategoriesData] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [rates, setRates] = useState([]);
const [error, setError] = useState(null); // State for error handling
const [currencyError, setCurrencyError] = useState(null); // State for error handling
const router = useRouter(); // Hook to access router object
const axiosInstance = useAxios(); // Custom axios hook for API requests
const theme = useContext(ThemeContext);
// Responsive design: States for screen size detection
const isMediumScreen = useMatchMedia('(min-width: 1024px) and (max-width: 1200px)');
const isMediumLargeScreen = useMatchMedia('(min-width: 1201px) and (max-width: 1439px)');
const isLargeScreen = useMatchMedia('(min-width: 1440px)');
const { isMobile, isTablet } = useDeviceSize();
const [menuWidth, setMenuWidth] = useState('');
//console.log('navbar two', menuJsonApp)
 
   // Function to toggle category display in the menu
  //  const handleAllCategoriesClick = () => {
  //   setShowCategories(!showCategories);
  //   setActiveCategory(null);
  //   if (divRef.current) {
  //     setMenuWidth(divRef.current.clientWidth);
  //     //console.log('menuWidth', menuWidth)
  //   }
  // };
  const handleAllCategoriesClick = () => {
    setShowCategories(true);
    // setActiveCategory(null);
    if (divRef.current) {
      setMenuWidth(divRef.current.clientWidth);
      //console.log('menuWidth', menuWidth)
    }
  };

  // Function to handle category selection
  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    // Optionally close the categories dropdown here
  };

  // Function to handle nested category selection
  const handleNestedCategoryClick = () => {
    setShowCategories(false);
    setActiveCategory(null);
  };

    // Function to truncate text by word count
    function truncateByWord(text, wordLimit) {
      const words = text.split(" ");
      return words.length > wordLimit ? `${words.slice(0, wordLimit).join(" ")}...` : text;
    }

  // Ref for the div container
  const divRef = React.useRef(null);

  // Effect for handling outside clicks of dropdown menu
  useEffect(() => {
    // Function to check if clicked outside of dropdown
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowCategories(false);
        setActiveCategory(null);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // setMenuWidth(divRef.current.clientWidth);
    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);




  useEffect(() => {
    if (menuJsonApp && menuJsonApp.menu_list) {
      setCategoriesData(menuJsonApp.menu_list);
      setGoldPlan(menuJsonApp.savings_scheme_enable === 1 ? true : false); 
      setGoldPlanEnable(menuJsonApp.savings_scheme_enable === 1 ? true : false);
        // Shipping status
        setShippingEnable(menuJsonApp.shipping_enable === 1 ? true : false);

      if (menuJsonApp.metal_list) {
          const metalList = menuJsonApp.metal_list;

          // Filter out Gold 91.6 (22ct) and Silver 99.9
          const filteredMetalList = metalList.filter((metal) => {
            return (
              metal.metal_name === "Gold 91.6 (22ct)" ||
              metal.metal_name === "Silver 99.9"
            );
          });
          setRates(filteredMetalList);
        } else {
          // Handle the case when metal_list is null
          
          setRates([]); // Set rates to an empty array or handle accordingly
        }
        setIsLoading(false);
    }
  }, [menuJsonApp]);

  const hrefAttribute = theme.siteStatus === 0 ? `/?pid=${theme.token}` : '/';
  
 // JSX rendering of the Navbar component
  return (
    <>
      <Header rates={rates} loading={isLoading} />
      {/* {isMobile && <div className="top-12 z-50 py-1 shadow-sm w-full text-sm sticky bg-white transform translate-y-0">
        <MobileSearchBar />
      </div>} */}
      
      <nav className="bg-secondary hidden lg:block sticky top-0 z-50">
        <div className="block xl:container lg:px-8 xl:px-3" >
          <div className="flex">
            <div
        ref={divRef}
              //  onMouseLeave={handleAllCategoriesClick}
              onClick={handleAllCategoriesClick}
              className="px-8 py-4 bg-primary flex items-center cursor-pointer group relative"
            >
              <span className="text-white">
                <i className="fas fa-bars"></i>
              </span>
              <span
                className="capitalize ml-2 text-white "
                // onClick={handleAllCategoriesClick}
                // style={{fontFamily:"poppins"}}
              >
                all categories
              </span>
              {/* <div 
      onClick={() => setModalIsOpen(true)}
      className="fixed bottom-4 right-4 bg-green-500 text-white p-2 rounded-full cursor-pointer"
    >
      <FaWhatsapp size={32} />
    </div> */}
              <div>
                {showCategories &&
                  (isLoading ? (
                    <div className="absolute left-0 top-full w-full bg-white shadow-md py-3 transition duration-300 z-50 divide-y divide-gray-300 divide-dashed overflow-y-auto overflow-x-hidden max-h-78 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200" >
                      <div className="shimmer">
                        {Array.from({ length: 5 }).map((_, i) => (
                          <div
                            key={i}
                            className="px-6 py-3 flex items-center justify-between hover:bg-gray-100 transition cursor-pointer"
                          >
                            <span className="text-gray-700 text-sm font-semibold uppercase bg-gradient-to-r from-gray-300 to-gray-400 w-full h-4 rounded animate-shimmer"></span>
                            <i className="fas fa-chevron-right text-gray-500"></i>
                          </div>
                        ))}
                        <div className="px-6 py-3 flex items-center justify-between hover:bg-gray-100 transition cursor-pointer">
                          <span className="text-gray-700 text-sm font-semibold bg-gradient-to-r from-gray-300 to-gray-400 w-full h-4 rounded animate-shimmer"></span>
                          <i className="fas fa-chevron-right text-gray-500"></i>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="absolute left-0 top-full w-full  bg-white shadow-md py-3 transition duration-300 z-50 divide-y divide-gray-300 divide-dashed overflow-y-auto max-h-78 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                      {error ? (
                        <div className="bg-primary  text-white rounded-sm p-4 shadow-lg">
                          <div className="flex items-center">
                            <FaExclamationCircle className="text-2xl mr-2" />
                            <span className="font-bold">
                              An error has occurred:
                            </span>
                          </div>
                          <p className="mt-2">{error}</p>
                        </div>
                      ) : (
                        categoriesData?.map((category) => (
                          <div
                            key={category.id}
                            onMouseEnter={() =>
                              handleCategoryClick(category.id)
                            }
                            onClick={() =>
                              handleCategoryClick(category.id)
                            }
                            className="px-6 py-3 flex items-center justify-between hover:bg-gray-100 transition cursor-pointer"
                          >
                            <h1
                              className={
                                `text-sm  font-semibold uppercase ` +
                                (activeCategory === category.id
                                  ? `text-primary`
                                  : `text-gray-700`)
                              }
                             
                            >
                              {category.name}
                            </h1>
                            <i className="fas fa-chevron-right text-gray-500"></i>
                          </div>
                        ))
                      )}
                      {/* {categoriesData.length > 4 && !error && (
                      <div className="px-6 py-3 flex items-center justify-between hover:bg-gray-100 transition cursor-pointer">
                        <span
                          className="text-gray-700 text-sm font-semibold "
                          onClick={handleAllCategoriesClick}
                        >
                          View All Categories
                        </span>
                        <i className="fas fa-chevron-right text-gray-500"></i>
                      </div>
                    )} */}
                    </div>
                  ))}

                {activeCategory && (
                  <div className={`absolute left-0 overflow-x-hidden top-full bg-white shadow-md py-3 transition duration-300 z-50 divide-y divide-gray-300 divide-dashed overflow-y-auto max-h-78 md:max-h-[40rem] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200`} style={{ marginLeft: `${menuWidth}px` }}>
                    {categoriesData
                      .find((category) => category.id === activeCategory)
                      .child.reduce((acc, subCategory, index) => {
                        let isStartOfNewGrid;
                        if (isMediumScreen) {
                          isStartOfNewGrid = index % 3 === 0;
                        } else if (isMediumLargeScreen) {
                          isStartOfNewGrid = index % 4 === 0;
                        } else if (isLargeScreen) {
                          isStartOfNewGrid = index % 5 === 0;
                        } else {
                          // Default case for screens smaller than 1024px
                          isStartOfNewGrid = index % 5 === 0;
                        }
                        if (isStartOfNewGrid) acc.push([]);
                        acc[acc.length - 1].push(subCategory);
                        return acc;
                      }, [])
                      .map((subCategoryGroup) => (
                        <div className="grid grid-flow-col auto-cols-max space-x-4">
                        {subCategoryGroup.map((subCategory) => (
                          <div key={subCategory.id} className="px-6 py-3">
                            {subCategory.status === 1 ? (
                              <Link href={`${(subCategory.id && subCategory.url) || ''}`}>
                                <h2
                                  className="font-semibold uppercase text-primary"
                                  title={subCategory.name} // This will show the full name as tooltip on hover
                                >
                                  {subCategory.name.length > 8 ? subCategory.name : subCategory.name}
                                </h2>
                              </Link>
                            ) : (
                              <div
                                className="font-semibold uppercase text-primary"
                                title={subCategory.name} // This will show the full name as tooltip on hover
                              >
                                {subCategory.name.length > 8 ? subCategory.name : subCategory.name}
                              </div>
                            )}
                  
                            {subCategory.child.map((child) => (
                              <div
                                key={child.id}
                                className="block mt-2 text-sm text-gray-600 hover:text-gray-900 capitalize"
                              >
                                <a href={`${child.url}${theme.siteStatus === 0 ? `?pid=${theme.token}` : ''}`}>
                                  {child.name}
                                </a>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between flex-grow px-5 sm:px-20 gap-2 sm:gap-6">
              <div className="flex flex-col sm:flex-row items-center sm:space-x-6 text-base capitalize">
                <Link
                  href={hrefAttribute}
                  className="text-alternative hover:text-primary transition  font-semibold"
                >
                  Home
                </Link>
                {/* <Link
                  href="/Coins"
                  className="text-alternative hover:text-primary transition  font-semibold"
                >
                  Coins
                </Link> */}
                <Link
  href={
    theme.siteStatus === 0 
      ? `/AboutUs?pid=${theme.token}`
      : `/AboutUs`
  }
  className="text-alternative hover:text-primary transition font-semibold"
>
  About us
</Link>

<Link
  href={
    theme.siteStatus === 0 
      ? `/ContactUs?pid=${theme.token}`
      : `/ContactUs`
  }
  className="text-alternative hover:text-primary transition font-semibold"
>
  Contact us
</Link>

            
             
                {GoldPlan? <div className="flex cursor-pointer items-center text-alternative hover:text-primary transition">
                <Link
  href={
    theme.siteStatus === 0 
      ? `/GoldPlan/PlanList?pid=${theme.token}`
      : `/GoldPlan/PlanList`
  }
  className="lg:text-[15px] xl:text-[16px] font-semibold"
>
  Gold Plan
</Link>

                              {/* <FaCrown className="ml-2 text-lg"/> */}
                            </div>:false}
               
              </div>
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between sm:w-auto w-full mt-2 sm:mt-0">
                {/* <Link
                href={`/auth/SignIn`}
                className="text-alternative sm:ml-auto hover:text-primary transition  font-semibold capitalize"
              >
                login/register
              </Link> */}

                {/* <div className="relative  inline-block mt-2 sm:mt-0">
                 
                  <select
                    value={currentCurrency}
                    onChange={(e) => {
                      setCurrentCurrency(e.target.value);
                      router.push(
                        {
                          pathname: router.pathname,
                          query: { ...router.query, currency: e.target.value },
                        },
                        undefined,
                        { scroll: false }
                      );
                    }}
                    style={{
                      border: "none",
                      borderRadius: "none",
                      focus: "none",
                    }}
                    className="w-full text-primary font-semibold px-4 py-2 border pr-8 border-gray-300 bg-transparent shadow-sm rounded
focus:ring-primary focus:border-primary"
                  >
                    {currencyData &&
                      Object.entries(currencyData).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value.name}
                        </option>
                      ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-primary"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
