import React, { useContext, useState, useEffect } from "react";
import Fab from '@mui/material/Fab';
import { UserContext } from '../../contexts/UserContext';
import { useDeviceSize } from "../../utils/useDeviceSize";

const PhoneIconTwo = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [phoneNo, setPhoneNo] = useState('');
    const { isMobile, isTablet } = useDeviceSize();

    useEffect(() => {
        if (featureCustomizations.mobile_number) {
            setPhoneNo(featureCustomizations.mobile_number);
        }
    }, [featureCustomizations]);

    const mobileStyle = {
        position: 'fixed',
        bottom: '60px',
        right: '80px',
        zIndex: '9999',
        width: '40px',
        height: '40px'
    };

    const tabletStyle = {
        position: 'fixed',
        bottom: '50px',
        right: '65px',
        zIndex: '9999',
        width: '40px',
        height: '40px'
    };

    const defaultStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '80px',
        zIndex: '9999',
        width: '50px',
        height: '50px'
    };

    const fabStyle = isMobile ? mobileStyle : isTablet ? tabletStyle : defaultStyle;

    return (
        <Fab color="primary" aria-label="add" style={fabStyle}>
            <a
                href={`tel:${phoneNo}`}
                className="p-2 lg:p-3 hover:text-primary transition rounded-full shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
                <i className="fas fa-phone-alt text-lg"></i>
            </a>
        </Fab>
    );
};

export default PhoneIconTwo;
