// utils/axios.js
import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { BASE_URL } from './config';
import * as Sentry from "@sentry/react";


/**
 * Custom hook to create an Axios instance with specific configurations and interceptors.
 * It leverages contexts for job ID and currency to enrich each request with additional data.
 *
 * @returns {AxiosInstance} - The configured Axios instance.
 */
export function useAxios() {
     // Accessing job ID and currency from their respective contexts.
     const { jboId } = useJobId();
     const theme = useContext(ThemeContext);
    //console.log("jboIdAxiosconsole",jboId);
    const { userId, token } = useContext(UserContext);
    const { currentCurrency } = useContext(CurrencyContext); // new line
  
    // Creating an Axios instance with a predefined base URL and headers.
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        // add other headers as needed
      },
      timeout: 30000, // Timeout in millisecosdfds
  
    });
  
  // Interceptor to modify every outgoing request.
    instance.interceptors.request.use((config) => {
   // Ensuring the request has a 'data' property and it's an object.
      // Adding job ID and currency information to the request's payload.
      // const adjustedUserId = theme.siteStatus === 0 ? '0' : (userId ? userId : null);
      config.data = {
        data: JSON.stringify({
          ...config.data,
          client_id:jboId,
          jbo_id: jboId,
          user_id: userId,
          currency: currentCurrency, // add this line
        }),
      };
      return config;
    }, (error) => {
      // Do something with request error
      Sentry.captureException(error);
      return Promise.reject(error);
    });
  

  // Interceptor to handle response errors globally, including retry logic
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.error('API request failed:', error);
      Sentry.captureException(error);

      const jboID = jboId;
      const endpoint = error.config.url;
      const payload = error.config.data;
      const responseData = error.response ? error.response.data : null;
      const errorMsg = error.message;
      const timestamp = new Date().toISOString();
      //console.log('----------------------------------------------------------------')

      //console.log('Failed endpoint:', endpoint);
      //console.log('Request payload:', payload);
      //console.log('Response data:', responseData);
      //console.log('error message:', errorMsg);
      //console.log('Timestamp:', timestamp);
      //console.log('JBO ID:', jboID);

      //console.log('----------------------------------------------------------------')


      // Check if the error is a 502 and decide if we should retry
      if (error.response && error.response.status === 502) {
        // Implement retry logic here
        // Example: retry the request once after a delay of 1000ms
        await new Promise(resolve => setTimeout(resolve, 1000));
        return instance.request(error.config);
      }

      return Promise.reject(error);
    }
  );

  return instance;
}