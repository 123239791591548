// Importing useState and useEffect hooks from React
import { useState, useEffect } from 'react';

/**
 * Custom hook for responding to media query changes.
 * 
 * This hook allows you to use CSS-like media queries in your React components
 * for responsive design purposes. It listens to changes in the media query
 * and updates the component state accordingly.
 *
 * @param {string} query - The media query string.
 * @returns {boolean} - A state variable that indicates whether the media query matches.
 */
function useMatchMedia(query) {
  // State to store whether the media query matches
  const [matches, setMatches] = useState(false);

  // useEffect hook to handle side effects related to media query changes
  useEffect(() => {
    // Creating a MediaQueryList object representing the parsed media query
    const media = window.matchMedia(query);

    // Check if the current state matches the media query
    if (media.matches !== matches) {
      // Update state if there's a mismatch
      setMatches(media.matches);
    }

    // Define a listener function that updates the state when media query status changes
    const listener = () => setMatches(media.matches);

    // Add the listener to the MediaQueryList object
    media.addListener(listener);

    // Cleanup function to remove the listener when the component unmounts or dependencies change
    return () => media.removeListener(listener);
  }, [matches, query]); // Effect dependencies include both the matches state and the query string

  // Return the state indicating whether the query matches
  return matches;
}

// Exporting the custom hook for use in other components
export default useMatchMedia;
