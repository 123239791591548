import React, { createContext, useState, useEffect,useContext } from 'react';
import { useAxios } from "../utils/axios";
import { ThemeContext } from '../contexts/ThemeContext';
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { useJobId } from '../contexts/ContextJbo';
import errorLogApi from '.././components/custom/ErrorLogApi';
// Creating a Wishlist contex
export const WishlistContext = createContext();
/**
 * WishlistProvider component to manage and provide the wishlist state to the application.
 * 
 * This component handles fetching, updating, and removing items from the wishlist. It uses
 * the UserContext for user details and Axios for API calls.
 * 
 * @param {React.ReactNode} children - The child components of WishlistProvider.
 */
export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const theme = useContext(ThemeContext);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [wishlistRender, setWishlistRender] = useState(true); // Flag to trigger re-render
  const axios = useAxios();
  const { userId, token } = useContext(UserContext);
  const { currentCurrency } = useContext(CurrencyContext); // new line
  const { jboId } = useJobId();
  const [errorState, setErrorState] = useState(false);
// Fetching the user's wishlist from the server
  const fetchWishlist = async () => {
    try {
      const payload = {
        user_id: userId,  // replace with actual user ID
        token:token,
        product_id:[]
      };

      const response = await axios.post('product/getWishList', payload);
      
      if (response.data) {
        setLoading(false);
        setWishlist(response.data.product);
      }
      setErrorState(false);
    } catch (error) {
      setLoading(false);
      setErrorState(true);
      const jboID = jboId;
      const endpoint = 'product/getWishList';
      const payLoad = {
        user_id: userId,  // replace with actual user ID
        token:token,
        product_id:[]
      };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg
      });
      
    }
  };

  // Call fetchWishlist when the component mounts
  useEffect(() => {
    if (userId  && userId !=="null" && token && theme.siteStatus === 1) { // Only fetch wishlist if userId and token are set
   
      fetchWishlist();
      // fetchWishlistCount();
    } else {

      // If userId or token is null (i.e., user is logged out), clear the wishlist
      setWishlist([]);
    }
    fetchWishlistCount();
  }, [wishlistRender,currentCurrency,userId,token]); // Trigger the effect only when wishlistRender changes
 // Fetching the count of items in the user's wishlist
const fetchWishlistCount = async () => {
  try {
    const response = await axios.post('user/UserWishListCount',{user_id: userId});
    if (response.data && response.data.count) {
      setWishlistCount(response.data.count);
    }
  } catch (error) {
    const jboID = jboId;
    const endpoint = 'user/UserWishListCount';
    const payLoad = {user_id: userId};
    const responseData = error?.response?.data ? error.response.data : '';
    const errorMsg = error;
    errorLogApi({
      job_id: jboID,
      endpoint: endpoint,
      payload: payLoad,
      responseData: responseData,
      errorMsg: errorMsg
    });
  }
};
 // Adds a product to the wishlist
  const addToWishlist = async (product) => {
    const payload1 = {
      user_id: userId,  // replace with actual user ID
      token:token,  // replace with actual user ID
      quantity:1,
      product_id: product.product_id ? product.product_id : product.id,
    };

    try {
      const response = await axios.post('/user/addToWishList', payload1);
      
      if (response.data.status === "1") {
        fetchWishlist(); // Fetch updated wishlist after adding a product
        fetchWishlistCount();
        setWishlistRender(!wishlistRender); // Toggle the flag to trigger re-render
      }
    } catch (error) {
      const jboID = jboId;
      const endpoint = '/user/addToWishList';
      const payLoad = {
        user_id: userId,  // replace with actual user ID
        token:token,  // replace with actual user ID
        quantity:1,
        product_id: product.product_id ? product.product_id : product.id,
      };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg
      });
    }
  };
// Removes a product from the wishlist
  const removeFromWishlist = async (product) => {
    const payload2 = {
      user_id: userId,  // replace with actual user ID
      token:token,
      product_id: product.product_id ? product.product_id : product.id,
    };
  
    try {
      const response = await axios.post('/user/removeWishList', payload2);
      if (response.data.status === 1) {
        fetchWishlistCount();
        fetchWishlist()
          .then(() => {
            setWishlist((prevWishlist) =>
              prevWishlist.map((item) =>
                item.product_id === product.id
                  ? { ...item, isRemoved: true }
                  : item
              )
            );
          })
          .then(() => setWishlistRender((prevState) => !prevState));
      }
    } catch (error) {
      const jboID = jboId;
      const endpoint = '/user/removeWishList';
      const payLoad = {
        user_id: userId,  // replace with actual user ID
        token:token,
        product_id: product.product_id ? product.product_id : product.id,
      };
      const responseData = error?.response?.data ? error.response.data : '';
      const errorMsg = error;
      errorLogApi({
        job_id: jboID,
        endpoint: endpoint,
        payload: payLoad,
        responseData: responseData,
        errorMsg: errorMsg
      });
    }
  };
  
  // Providing the wishlist context to child components

  return (
    <WishlistContext.Provider value={{ wishlist,loading,setLoading, addToWishlist, removeFromWishlist,wishlistCount, errorState }}>
      {children}
    </WishlistContext.Provider>
  );
};
