// Importing necessary hooks and components from React
import React, { useState, useEffect, useContext } from 'react';
// Importing Navbar components for different menu types
import Navbar from './Navbar'; // Simple Menu
import NavBarTwo from './NavBarTwo'; // Mega Menu
// Importing ThemeContext to access theme-specific data
import { ThemeContext } from "../../contexts/ThemeContext";
import axios from "axios";
// Custom hook for using axios with configurations
import { useAxios } from "../../utils/axios";
import { BUILDER_URL } from '../../utils/config';
import { UserContext } from "../../contexts/UserContext";
// Define base URL from global config
const BASE_URL = BUILDER_URL;
import * as Sentry from "@sentry/react";

/**
 * MainNavBar component for the application.
 * 
 * This component is responsible for rendering the main navigation bar of the application.
 * It fetches menu data from an API and decides which type of Navbar to render based on the
 * fetched data. It supports two types of navigation bars: a simple menu and a mega menu.
 *
 * @returns {React.ReactElement} - The Main Navigation Bar component.
 */
const MainNavBar = () => {
    // State to store the type of menu and categories data
    // Default menu state updates in to empty string - 29/06/2024
    const [menuType, setMenuType] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    
    // get the categories data for thumbnail status in breadcrumbs - 05/08/2024
    const { setGoldRate, setMetalCategories, setMenuJsonApp,setMenuData } = useContext(UserContext); 

    // Accessing the theme context
    const theme = useContext(ThemeContext);

    // Instance of axios with custom configurations
    const CustomAxios = useAxios();

    // Fetching menu type data on component mount or theme change
    useEffect(() => {
        axios.get(`${BASE_URL}/menu-bar`, {
            headers: { 'Authorization': `Bearer ${theme.token}` }
        })
        .then((response) => {
            if (response.data && response.data.length > 0) {
                setMenuType(response.data[0].type_of_menu); // Setting the menu type
            }
        })
        .catch((error) => {
            Sentry.captureException(error);
        });
    }, [theme]);

    // Fetching categories data for the menu
    useEffect(() => {
        CustomAxios
          .post("/menu/menuJsonApp")
          .then((response) => {
            // Handle the response
            setCategoriesData(response.data.menu_list); // Setting the categories data
            setMenuData(response.data.menu_list); // Setting the categories data for thumbnail status in breadcrumbs - 05/08/2024
            setGoldRate(response.data.metal_list);
            setMetalCategories(response.data.menu_list);
            setMenuJsonApp(response.data);
            // //console.log("Gold Rate",response.data.metal_list);
          })
          .catch((error) => {
            Sentry.captureException(error); // Error handling
          });
      }, [theme]);

    // Conditional rendering of Navbar based on menu type or number of categories -20/08/2024
    return (
        <>
            {menuType === "Megamenu" || categoriesData.length > 6 ? (
            <NavBarTwo />
        ) : menuType === "Simple menu" ? (
            <Navbar />
        ) : null}
        </>
    );
}

// Exporting the MainNavBar component for use in other parts of the application
export default MainNavBar;
