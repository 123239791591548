import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const Custom404 = () => {
  const { errorImages,errorLoading } = useContext(UserContext);
  const defaultImage = "/images/502.jpeg";
  const errorImage = errorImages?.somethingWentWrong || defaultImage;
if(errorLoading){
  return(<>
  <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
        <div className="w-3/4 md:w-1/2 bg-gray-300 animate-pulse" style={{ height: '300px', maxWidth: '100%' }}></div>
      </div>
  </>)
}
  return (
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
      <img
        src={errorImage}
        alt="Error"
        className="w-3/4 md:w-1/2 object-contain "
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

const Error404Order = () => {
  const { errorImages,errorLoading } = useContext(UserContext);
  const defaultImage = "/images/502.jpeg";
  const errorImage = errorImages?.somethingWentWrong || defaultImage;
  if(errorLoading){
    return(<>
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
          <div className="w-3/4 md:w-1/2 bg-gray-300 animate-pulse" style={{ height: '300px', maxWidth: '100%' }}></div>
        </div>
    </>)
  }
  return (
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
      <img
        src={errorImage}
        alt="Error"
        className="w-3/4 md:w-1/2 object-contain "
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

const PageNotFound = () => {
  const { errorImages,errorLoading } = useContext(UserContext);
  const defaultImage = "https://img.freepik.com/free-vector/page-found-concept-illustration_114360-1869.jpg?size=338&ext=jpg&ga=GA1.1.1413502914.1720051200&semt=sph";
  const errorImage = errorImages?.pageNotFound || defaultImage;
  if(errorLoading){
    return(<>
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
          <div className="w-3/4 md:w-1/2 bg-gray-300 animate-pulse" style={{ height: '300px', maxWidth: '100%' }}></div>
        </div>
    </>)
  }
  return (
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
      <img
        src={errorImage}
        alt="Page Not Found"
        className="w-3/4 md:w-1/2 object-contain "
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

const ProductNotFound = () => {
  const { errorImages,errorLoading } = useContext(UserContext);
  const defaultImage = "/images/404/404-error.jpg";
  const errorImage = errorImages?.productNotFound || defaultImage;
  if(errorLoading){
    return(<>
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
          <div className="w-3/4 md:w-1/2 bg-gray-300 animate-pulse" style={{ height: '300px', maxWidth: '100%' }}></div>
        </div>
    </>)
  }
  return (
    <div className="flex items-center p-2 justify-center min-h-[50vh] md:min-h-screen">
      <img
        src={errorImage}
        alt="Product Not Found"
        className="w-3/4 md:w-1/2 object-contain "
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

const NoDataFound = () => {
  const { errorImages,errorLoading } = useContext(UserContext);
  const defaultImage = "https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527142.jpg?size=626&ext=jpg&ga=GA1.1.946237680.1700816731&semt=ais";
  const errorImage = errorImages?.noDataFound || defaultImage;
  if(errorLoading){
    return(<>
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
          <div className="w-3/4 md:w-1/2 bg-gray-300 animate-pulse" style={{ height: '300px', maxWidth: '100%' }}></div>
        </div>
    </>)
  }
  return (
    <div className="flex items-center justify-center p-2 min-h-[50vh] md:min-h-screen">
      <img
        src={errorImage}
        alt="No Data Found"
        className="w-3/4 md:w-1/2 object-contain "
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default Custom404; // Set Custom404 as the default export
export { Error404Order, PageNotFound, ProductNotFound, NoDataFound }; // Continue to export other components as named exports
  