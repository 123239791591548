import React from 'react'
import { useRouter } from 'next/router';
const Empty = () => {
    const router = useRouter();

    const isHomePage = router.pathname === '/';
  return (
    <div className="flex flex-col items-center justify-center h-screen">
    {/* <img src="/images/404/notfound.svg" alt="Error" className="w-60 h-60 mb-8" />
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-4">Oops!</h2>
      <p className="text-lg text-gray-800">An unexpected error occurred. We apologize for the inconvenience. Please try again later.</p>
    </div>
    {!isHomePage && (
      <button 
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-3 px-4 rounded"
        onClick={() => router.push('/')}>
        Back to Home
      </button>
    )} */}
  </div>
  )
}

export default Empty
