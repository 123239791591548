// Import necessary libraries and components
import React,{useContext,useState,useEffect,useRef,useCallback} from "react";
import Image from "next/image";
import Link from '../../components/custom/Link';
import logo from "../../public/images/flogo.png";
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { useRouter } from 'next/router';
// Import CartContext from context
import { CartContext } from '../../contexts/CartContext';
import { WishlistContext } from '../../contexts/WishlistContext';
import { UserContext } from '../../contexts/UserContext';
import {ThemeContext} from "../../contexts/ThemeContext";
import axios from "axios"
import { RiLineChartLine } from 'react-icons/ri'; // Represents market trends
import { AiOutlineBulb } from 'react-icons/ai'; // Symbolizes planning and ideas
import { BUILDER_URL, setImageSrc } from '../../utils/config';
// Define base URL from global files
const BASE_URL = BUILDER_URL;
import * as Sentry from "@sentry/react";
import ContactUs from "../ContactIcons/ContactUs";
import {useDeviceSize} from "../../utils/useDeviceSize";
import DeskTopSearchBar from "../Search/DesktopSearchBar";
import MobileSearchBar from '../Search/MobileSearchBar';

/**
 * Header component for the application.
 * 
 * This component displays the header of the application, including navigation links,
 * search functionality, and user-specific actions. It uses multiple contexts to 
 * handle user, cart, wishlist, and theme data.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.rates - Currency rates object.
 * @param {boolean} props.loading - Loading state for asynchronous operations.
 * @returns {React.ReactElement} - The Header component.
 */

const Header = ({rates,loading}) => {
    // Return null if rates are not available
    if (rates === undefined) {
      return null; // Optionally, return a default or loading state
    }
  
    // Contexts to access user, cart, wishlist, and theme data
    const { userId, token,goldRate, setFeatureCustomizations,setErrorLoading,setLogoUrl,setThumbnailStatus,userDetails,setErrorImages } = useContext(UserContext);
    const { cartCount,cart } = useContext(CartContext);
    const {wishlist,wishlistCount } = useContext(WishlistContext);
    const { formatCurrency, currentCurrency, setCurrentCurrency, currencyData, setCurrencyData } = useContext(CurrencyContext);
    const theme = useContext(ThemeContext);
  
    // Local state management
    const [searchValue, setSearchValue] = useState('');
  
    const [logoImage, setLogoImage] = useState('');
    const [headerType, setHeaderType] = useState('');
    const [tokenRender, setTokenRenders] = useState(false);
    const { isMobile, isTablet } = useDeviceSize();
    const [checkIflistPage, setCheckIflistPage] = useState(true);
    const router = useRouter(); // get the router
    // Get the current path
  const currentPath = router.pathname;


    const { slug } = router.query;

useEffect(() => {
  // Update the state if the current path includes 'search'
  if (currentPath.includes('search')) {
    setCheckIflistPage(false);
  } else {
    // Handle different slug scenarios
    if (slug?.length === 1) {
      setCheckIflistPage(true);
    } else if (!slug?.includes('buy') && slug?.length === 3) {
      setCheckIflistPage(false);
    } else {
      setCheckIflistPage(true);
    }
  }
}, [currentPath, slug, router.query]);
    const inputRef = useRef(); // Ref for the search input
  
    // UseEffect for handling URL query parameters and token updates
    useEffect(() => {
      // Handling currency change from URL query
      const currentCurrencyFromUrl = router.query.currency;
      if (currentCurrencyFromUrl && currencyData[currentCurrencyFromUrl]) {
        setCurrentCurrency(currentCurrencyFromUrl);
      }
    }, [router, currencyData, setCurrentCurrency]);
  
    useEffect(() => {
      // Triggering re-render on token change
      setTokenRenders(!tokenRender);
    }, [token]);
  
    // Function to handle search submissions
    const handleSearch = (e) => {
      e.preventDefault();
      const searchUrl = theme.siteStatus === 0  ? `/search/${searchValue}?pid=${theme.token}` : `/search/${searchValue}`;
      // router.push(searchUrl);
      window.location.href=searchUrl;
      setSearchValue('');
      inputRef.current.blur();
    };
    
  
    // Function to trigger search on Enter key
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleSearch(e);
      }
    };
    function toggleLiveChat() {
      // Logic to open live chat window
    }
    // Fetching header data using axios
    useEffect(() => {
      axios.get(`${BASE_URL}/title-bar`, {
          headers: { 'Authorization': `Bearer ${theme.token}` }
        })
        .then((response) => {
          setErrorLoading(false);
          // Handle successful response
          if (response.data && response.data.length > 0) {
            const logoUrl = response.data[0].logo_url;
            const headerTypeData = response.data[0].title_bar_type;
            setLogoImage(logoUrl);
            setLogoUrl(logoUrl);
            
            // Set the state with the custom errror response data
    setErrorImages({
      pageNotFound: response.data[0].page_not_found_screen_url,
      somethingWentWrong: response.data[0].somethin_went_wrong_screen_url,
      noDataFound: response.data[0].no_data_found_screen_url,
      productNotFound: response.data[0].product_not_found_screen_url
    });
              // Store logoUrl in session storage
        sessionStorage.setItem('logoUrl', logoUrl);
            setFeatureCustomizations(response.data[0]);
          }
        })
        .catch((error) => {
          setErrorLoading(false);
          Sentry.captureException(error); // Handle error response
        });
        
    }, [theme.token]);
  // handle login redirecions
    const handleLoginClick = (e) => {
      e.preventDefault();
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;
      router.push('/auth/SignIn');
    };
    // Conditional link component
    const ConditionalLink = ({ href, className, children }) => {
      const { token,userDetails } = useContext(UserContext);
      const router = useRouter();
  
      // Click handler for the link
      const handleClick = useCallback((e) => {
        if (!userDetails) {
          e.preventDefault();
          const url = window.location.href;
          document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;
    
          router.push('/auth/SignIn');
        } else if (theme.siteStatus === 0) {
          //console.log("site Status-works")
          // e.preventDefault();
        }
      }, [token, router]);
  
      return (
        <Link href={href} className={className} onClick={handleClick}>
          {children}
        </Link>
      );
    };
    
    const catClick = () => {
      var menu = document.getElementById("catMenu");
  // If the categories menu is hidden, remove the 'hidden' class to display it
      if (menu?.classList.contains("hidden")) {
        menu?.classList.remove("hidden");
      }
    };
    const hrefAttribute = theme.siteStatus === 0 ? `/?pid=${theme.token}` : '/';
    const goldRates = [
      {
          "metal_name": "91.6 (22 KT)",
          "metal_rate": 6185
      },
      {
          "metal_name": "75 (18 KT)",
          "metal_rate": 5060
      },
      {
          "metal_name": "SILVER",
          "metal_rate": 80.5
      }
  ];
  
    // Return JSX for Header
  return (
    <>
    <div className="sticky top-0 z-50 lg:static" id="aboveContent">

      {/* Header component starts here */}
      {/* {!formatCurrency(rates[0]?.metal_rate) === '₹ undefined' &&
      <div className="w-full lg:w-[17rem] h-10 text-white text-center p-2 relative overflow-hidden bg-secondary lg:hidden">
        {
          loading ? 
          <div className="absolute w-full h-full flex flex-col text-primary justify-center items-center space-y-2 transform translate-y-full animate-slide">
            <div className="animate-shimmer bg-gradient-to-r from-gray-400 via-white to-gray-400 h-4 w-3/4 rounded"></div>
            <div className="animate-shimmer bg-gradient-to-r from-gray-400 via-white to-gray-400 h-4 w-3/4 rounded"></div>
          </div> : 
          <div className="absolute w-full h-full flex flex-col text-primary justify-center items-center space-y-2 transform translate-y-full animate-slide">
            
             <>
            
            <div className="text-lg font-semibold">{rates[0]?.metal_name}:  {formatCurrency(rates[0]?.metal_rate)}/gm</div>
            <div className="text-lg font-semibold">{rates[1]?.metal_name}:  {formatCurrency(rates[1]?.metal_rate)}/gm</div>
            </>
          </div>
        }
      </div>
            } */}
             <>
            {/* Gold Rates Marquee */}
            {goldRate && goldRate.length > 0 &&          
            <div className="bg-secondary py-2  overflow-hidden">
              <div className="flex items-center justify-between mx-auto px-4 max-w-screen-xl">
                {/* Left side static content */}
                <div className="items-center space-x-4 hidden lg:flex">
                  <span className="text-sm text-primary font-semibold text-gray-700">Today's Metal Rate</span>
                  <RiLineChartLine className="text-yellow-500 w-6 h-6 " /> &nbsp;
                </div>
                
                {/* Marquee for dynamic content, aligned centrally */}
                <div className="flex-grow flex items-center justify-center">
                  <marquee className="space-x-4  text-gray-600" scrollamount="3">
                    {goldRate.map((metal, index) => (
                      <span key={index} className="whitespace-nowrap text-primary mr-3" >
                        <span className="font-semibold ">{metal.metal_name}:</span> {formatCurrency(metal.metal_rate)}
                      </span>
                    ))}                   
                  </marquee>
                </div>

                {/* Right side static content, aligned centrally */}
                <div className="items-center space-x-4 hidden lg:flex">
                  <span className="text-sm font-semibold text-green-600 text-primary">Plan Your Investment</span>
                  <AiOutlineBulb className="text-green-500 w-6 h-6" />
                </div>
              </div>
            </div>}
        </>
      {/* Chat and Call*/}
      {
      // (!isMobile || !isTablet) &&
      <div>
        <ContactUs />
      </div>}
     


        <header className={`top-0 z-50 lg:py-2 py-0 shadow-sm bg-secondary w-full text-sm md:text-base lg:text-lg 'lg:static sticky'  lg:bg-white`}>
          <div className="flex xl:container py-2 lg:px-3 xl:px-3 gap-5 justify-between mx-3 ">
            {/* Logo section starts here */}
            <div className="text-2xl flex justify-center items-center lg:hidden">
              <i className="fas fa-bars" onClick={catClick}></i>
            </div>
            <Link href={hrefAttribute} className="flex justify-center px-1 lg:ml-0">
  {logoImage && <Image src={setImageSrc(logoImage, '')}   alt="logo" width={180} priority={true}  height={150} className="object-contain" />}
</Link>

         
            {/* Logo section ends here */}
            {/* Search bar section starts d here */}
            {(!isMobile || !isTablet) && 
            <DeskTopSearchBar /> }
      {/* {
        isTablet && (
          <div className="flex justify-between gap-5 ml-10 lg:ml-0">
          <ConditionalLink href="/Cart" className="block lg:hidden text-center text-gray-700 hover:text-primary transition relative j">
            <span className="absolute -right-2 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs">{cart ?.length}</span>
            <div className="text-3xl"><i className="fas fa-shopping-bag "></i></div>
          </ConditionalLink>
        </div>
        )
      } */}
      {/* Search bar section ends here */}

            {/* Navicons section starts here */}
            <div className="flex items-center lg:space-x-2">

              {/* Each navicon is a link leading to a specific page, with a hover effect */}
              {/* The Wishlist navicon */}
            
              {/* // Wishlist Link */}
              {theme.siteStatus === 0 ?  <Link href={theme.siteStatus === 0  ? "/profile/Wishlist?pid=" + theme.token : "/profile/Wishlist"} className="text-center text-gray-700 hover:text-primary transition relative hidden lg:block">
                <span className="absolute -right-0 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs">
                {theme.siteStatus === 0 ? 10 : wishlistCount || wishlist?.length}
                </span>
                <div className="text-2xl"><i className="far fa-heart"></i></div>
                <div className="text-sm leading-3 font-semibold">Wishlist</div>
              </Link>: <ConditionalLink href={theme.siteStatus === 0  ? "/profile/Wishlist?pid=" + theme.token : "/profile/Wishlist"} className="text-center text-gray-700 hover:text-primary transition relative hidden lg:block">
                <span className="absolute -right-0 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs">
                {theme.siteStatus === 0 ? 10 :wishlistCount || wishlist?.length}
                </span>
                <div className="text-2xl"><i className="far fa-heart"></i></div>
                <div className="text-sm leading-3 font-semibold">Wishlist</div>
              </ConditionalLink>}
             
             
              {/* // Cart Link */}
              {theme.siteStatus === 0 ? <Link href={theme.siteStatus === 0  ? "/Cart?pid=" + theme.token : "/Cart"} className=" text-center text-gray-700 hover:text-primary transition relative">
                <span className="absolute -right-2 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs">  {theme.siteStatus === 0 ? 10 : cartCount || cart.length}</span>
                <div className="text-2xl"><i className="fas fa-shopping-bag"></i></div>
                <div className="text-sm leading-3 hidden lg:block font-semibold">Cart</div>
              </Link>: <ConditionalLink href={theme.siteStatus === 0  ? "/Cart?pid=" + theme.token : "/Cart"} className=" text-center text-gray-700 hover:text-primary transition relative">
                <span className="absolute -right-2 -top-1 w-5 h-5 rounded-full flex items-center justify-center bg-primary text-white text-xs">  {theme.siteStatus === 0 ? 10 : cartCount || cart.length}</span>
                <div className="text-2xl"><i className="fas fa-shopping-bag"></i></div>
                <div className="text-sm leading-3 hidden lg:block font-semibold">Cart</div>
              </ConditionalLink>}
              
              
              {theme.siteStatus !== 0 && <div className="hidden lg:block">
              {
              token && userId !== 'null' && userId && userId !== 'null'
              ? <Link href="/profile/User" className="text-center text-gray-700 hover:text-primary transition relative">
              <div className="text-2xl"><i className="fas fa-user"></i></div>
              <div className="text-xs sm:text-sm md:text-base lg:text-sm leading-3 font-semibold">Account</div>
              </Link>
              :
              <span onClick={handleLoginClick} className="text-center text-gray-700 cursor-pointer hover:text-primary transition relative">
              <div className="text-2xl"><i className="fas fa-user"></i></div>
              <div className="text-xs sm:text-sm md:text-base lg:text-sm leading-3 xl:text-sm font-semibold">Login</div>
              </span>
              }
              </div>}
            </div>
            {/* Navicons section ends here */}

          </div>
        </header>
       

      {isMobile && checkIflistPage &&
      <div className="z-50 py-1 shadow-sm w-full text-sm sticky bg-white transform translate-y-0">
        <MobileSearchBar />
      </div>}
      {/* Header component ends here */}
      </div>
    </>
  );
};

// Export Header component
export default Header;
