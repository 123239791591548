import React, { useContext, useState, useEffect } from "react";
import Fab from '@mui/material/Fab';
import { UserContext } from '../../contexts/UserContext';
import { useDeviceSize } from "../../utils/useDeviceSize";
import { useJobId } from '../../contexts/ContextJbo';

const ChatIcon = () => {
    const { featureCustomizations } = useContext(UserContext);
    const [chatLink, setChatLink] = useState('');
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const { isMobile, isTablet } = useDeviceSize();
    const { jboId } = useJobId();

    useEffect(() => {
        if (featureCustomizations.chat_link) {
            setChatLink(featureCustomizations.chat_link);
        }
    }, [featureCustomizations]);

    const loadScript = () => {
        if (document.querySelector('script[src*="widget.js"]')) {
            // Script is already loaded
            setScriptLoaded(true);
            return;
        }
        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.type = "text/javascript";
        script.src = "https://app.wacto.in/static/js/widget.js?config=" + JSON.stringify({
            bot_key: "ad2a23e626b84e63",
            welcome_msg: true,
            branding_key: "wacto",
            server: "https://app.wacto.in",
            e: "p"
        });
        document.head.appendChild(script);

        script.onload = () => {
          
            setScriptLoaded(true);
        };

        script.onerror = () => {
            console.error('Script loading failed');
            setScriptLoaded(false); // Handle the error scenario
        };
    };

    useEffect(() => {
        if (jboId === 233) {
            loadScript();
        }
    }, [jboId]);

    const toggleLiveChat = () => {
        if (jboId !== 233 || !scriptLoaded) return; // Ensure toggle only works when jboId is 233 and script is loaded

        // Here you would add the logic to open the live chat widget if it has a specific method or event
        // For example:
        if (window.WactoWidget) {
            window.WactoWidget.openChat();
        }

        // Assuming there's an API to close the chat widget or if you have a way to determine when it closes
        // For example, if it closes after 5 minutes:
        setTimeout(() => {
            document.head.removeChild(document.querySelector('script[src*="widget.js"]'));
            setScriptLoaded(false); // Script has been removed
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    };

    if (jboId === 233) {
        return null; // Do not render the chat icon if jboId is 233
    }

    return (
        <>
            {
                (isMobile || isTablet) ?
                    <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '50px', right: '20px', width: '40px', height: '40px' }}>
                        <a className="hover:text-primary" href={chatLink}
                            onClick={(e) => {
                                if (!chatLink) {
                                    e.preventDefault();
                                } else {
                                    toggleLiveChat();
                                }
                            }}
                        >
                            <i className="fas fa-comments text-lg"></i>
                        </a>
                    </Fab> :
                    <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px', width: '50px', height: '50px' }}>
                        <a href={chatLink} className="hover:text-primary"
                            onClick={(e) => {
                                if (!chatLink) {
                                    e.preventDefault();
                                } else {
                                    toggleLiveChat();
                                }
                            }}
                        >
                            <i className="fas fa-comments text-xl lg:text-1xl"></i>
                        </a>
                    </Fab>
            }
        </>
    )
};

export default ChatIcon;
